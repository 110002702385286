import { Grid } from '@mui/material';
import CardWrapper from 'Common/components/CardWrapper';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusImageIcon from '../IconComponents/OpusImageIcon';
import TextOverflow from '../TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from '../TextOverflowComponent/TextOverflow';

interface CommonIconCellProps extends BaseCommonIconCell {
  linkUrl?: string;
  maxWidth: number;
  data?: BaseCommonIconCell[];
  maxIcons?: number;
  iconClass?: string;
}

export interface BaseCommonIconCell extends BaseComponentProps {
  iconUrl?: string;
  label: string;
}

export const CommonIconCell: FunctionComponent<CommonIconCellProps> = ({
  iconUrl,
  iconClass,
  label,
  linkUrl,
  maxWidth,
  data,
  maxIcons = 2,
}) => {
  const renderApplicationsList = (
    applications: BaseCommonIconCell[] = [],
    maxIcons: number = 2
  ) => (
    <Grid item xs={12} display="flex" gap={1} className="applications-list">
      {applications.slice(0, maxIcons).map((item) => (
        <OpusImageIcon
          src={item.iconUrl || ''}
          className="application-logo"
          key={item.label}
          title={item.label}
        />
      ))}
      {applications.length > maxIcons &&
        renderMoreApplicationsButton(applications, maxIcons)}
    </Grid>
  );

  const renderTooltipContent = (appList: BaseCommonIconCell[] = []) => (
    <Grid container className="risk-app-tooltip">
      {appList.map((app) => (
        <Grid item xs={12} key={app.label} className="risk-app-item">
          <OpusImageIcon src={app.iconUrl || ''} className="application-logo" />
          <p className="label-4">{app.label}</p>
        </Grid>
      ))}
    </Grid>
  );

  const renderMoreApplicationsButton = (
    applications: BaseCommonIconCell[],
    maxIcons: number
  ) => (
    <CardWrapper className="more-app-button" key={'more-app'}>
      <CommonTooltip
        placement="top"
        title={renderTooltipContent(applications.slice(maxIcons))}
      >
        <p className="body-5">+{applications.slice(maxIcons).length}</p>
      </CommonTooltip>
    </CardWrapper>
  );
  return (
    <Grid
      container
      className="common-icon-cell-container"
      spacing={iconUrl ? 3 : 0}
    >
      {data && data.length > 0 ? (
        renderApplicationsList(data, maxIcons)
      ) : (
        <>
          {iconUrl && (
            <Grid
              item
              xs={1}
              className={`common-icon-cell-icon ${iconClass || ''}`}
            >
              <img src={iconUrl} alt="icon" />
            </Grid>
          )}
          <Grid item xs={iconUrl ? 10 : 12}>
            {linkUrl ? (
              <a
                className="common-icon-cell-link"
                href={linkUrl}
                target="_blank"
              >
                <TextOverflow
                  direction={OverflowDirection.WIDTH}
                  type={OverflowTextDisplay.ELLIPSIS}
                  maxWidth={maxWidth}
                >
                  {label}
                </TextOverflow>
              </a>
            ) : (
              <div className="common-icon-cell-label">
                <TextOverflow
                  direction={OverflowDirection.WIDTH}
                  type={OverflowTextDisplay.ELLIPSIS}
                  maxWidth={maxWidth}
                >
                  {label}
                </TextOverflow>
              </div>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
