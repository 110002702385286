import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Common/store';
import { OrderState, OrderType } from 'Common/utils/sort';
import { MenuItems, SelectionItems } from 'DashboardFilter/store';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import {
  FilterItem,
  FilterOption,
} from 'shared/components/SearchFilter/AdvanceFilter';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import { riskGroupFilterItems } from 'shared/fixtures/data/risk-grid.data';
import { CategoryState } from 'shared/models/data/data-filter.model';
import { AssetsTabType } from 'Assets/interfaces/AssetsTabType';
import {
  assetsArtifactGridColumns,
  assetsCloudGridColumns,
  assetsDefaultGridColumns,
  assetsViewFilterCategories,
} from 'shared/fixtures/data/assets-grid.data';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
const advanceFilterDataHandler = new AdvanceFilterHandler();

export enum AssetstoreLocalVariable {
  AssetsGridColumns = 'assets_grid_columns',
}
export interface RiskFilterItem {
  value: string;
  label?: string;
  iconUrl?: string;
}

export interface RiskFilters {
  [key: string]: Array<RiskFilterItem> | RiskFilterItem | string;
}

export interface AssetsStoreSearchParams {
  filter: RiskFilters;
  order?: OrderState;
  skip: number;
  take: number;
  removeDuplications?: boolean;
}

export interface RiskItem {
  id: string;
  name?: string;
  status?: string;
}

interface AssetsStore {
  searchParams: AssetsStoreSearchParams;
  activeGridTab: AssetsTabType;
  gridFilterState: MenuItems;
  filterState: Record<string, CategoryState>;
  gridFilterSelections: SelectionItems;
  gridColumns: Partial<
    Record<
      AssetsTabType | `${AssetsTabType}_nested`,
      Array<ColDef | ColGroupDef>
    >
  >;
}

export const getInitialFilterState = () => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has('filter')) {
    const queryFilters = JSON.parse(searchParams.get('filter') || '');

    if (Object.keys(queryFilters).length === 0) {
      return {};
    }

    const filterState =
      advanceFilterDataHandler.translateQueryFiltersToFilterState(
        queryFilters,
        assetsViewFilterCategories
      );
    return filterState;
  } else {
    return {};
  }
};

export const AssetsDefaultOrder = {
  field: 'impactScore',
  type: OrderType.DESC,
};

getInitialFilterState();

const initialState: AssetsStore = {
  searchParams: {
    skip: 0,
    take: 25,
    filter: {},
  },
  activeGridTab: AssetsTabType.CODE,
  gridFilterState: riskGroupFilterItems,
  gridFilterSelections: [],
  gridColumns: {
    [AssetsTabType.CODE]: assetsDefaultGridColumns,
    [AssetsTabType.ARTIFACT]: assetsArtifactGridColumns,
    [AssetsTabType.CLOUD]: assetsCloudGridColumns,
    [AssetsTabType.ONPREM]: assetsCloudGridColumns,
    [AssetsTabType.DOMAIN]: assetsDefaultGridColumns,
  },
  filterState: getInitialFilterState(),
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setSearchParams: (
      state,
      action: PayloadAction<Partial<AssetsStoreSearchParams>>
    ) => {
      if (action.payload && Object.keys(action.payload).length) {
        state.searchParams = {
          ...state.searchParams,
          ...action.payload,
        };
      }
    },

    setActiveGridTab: (state, action: PayloadAction<AssetsTabType>) => {
      state.activeGridTab = action.payload;
    },

    setGridFilterState: (
      state,
      action: PayloadAction<
        MenuItems | { list: MenuItems; isSelectingItem: boolean }
      >
    ) => {
      const updatedSelections: SelectionItems = [];

      const listMenu = Array.isArray(action.payload)
        ? [...action.payload]
        : action.payload.list;
      const modifiedArray = listMenu.map((object) => {
        if (object.nested) {
          const nestedFilterItems: Array<FilterItem> = object.filterItems || [];

          for (const filterItem of nestedFilterItems) {
            if (filterItem.items.some((item: FilterOption) => item.checked)) {
              updatedSelections.push({
                id: filterItem.id,
                type: TypeFilter.MULTI_SELECT,
                selectionValues: filterItem.items?.filter(
                  (item: FilterOption) => item.checked
                ),
              });
            }
          }
        }

        if (
          object.dateRange ||
          object.items.some((item: any) => item.checked)
        ) {
          updatedSelections.push({
            id: object.id,
            type: object.type as TypeFilter,
            selectionValues:
              object.dateRange ||
              object.items?.filter((item: any) => item.checked),
          });
        }

        return {
          ...object,
          items: object.items.map((item) => {
            if (!item.hasOwnProperty('checked')) {
              return {
                ...item,
                checked: false,
              };
            }
            return { ...item };
          }),
        };
      });

      if (
        Array.isArray(action.payload) === false &&
        (action.payload as { list: MenuItems; isSelectingItem: boolean })
          .isSelectingItem
      ) {
        state.gridFilterSelections = updatedSelections;
      }
      state.gridFilterState = modifiedArray;
    },
    setGridColumns: (
      state,
      action: PayloadAction<{
        gridType: AssetsTabType | `${AssetsTabType}_nested`;
        columns: Array<ColDef | ColGroupDef>;
      }>
    ) => {
      state.gridColumns[action.payload.gridType] = action.payload.columns;

      localStorage.setItem(
        AssetstoreLocalVariable.AssetsGridColumns,
        JSON.stringify(state.gridColumns)
      );
    },
    clearGridFilterState: (state, action: PayloadAction<void>) => {
      let emptyFilterState: MenuItems = [...initialState.gridFilterState];

      for (const gridFilterStateKey in state.gridFilterState) {
        const filterState = state.gridFilterState;

        const updateFilterStateItems = filterState.map((obj: any) => {
          if (!obj?.dateRange) {
            return {
              ...obj,
              dateRange: null,
              items: obj.items.map((item: any) => {
                return { ...item, checked: false };
              }),
            };
          }
          return {
            ...obj,
            dateRange: null,
          };
        });

        emptyFilterState = updateFilterStateItems;
      }

      state.gridFilterState = emptyFilterState;
    },
    clearFilterState: (state) => {
      state.filterState = {};
    },
    removeFilterItem: (
      state,
      action: PayloadAction<{ categoryId: string }>
    ) => {
      const { categoryId } = action.payload;

      let updatedFilterState = { ...state.filterState };

      delete updatedFilterState[categoryId];

      state.filterState = updatedFilterState;
    },

    replaceFilterState: (
      state,
      action: PayloadAction<{
        updatedState: Record<string, CategoryState>;
      }>
    ) => {
      const { updatedState } = action.payload;

      state.filterState = {
        ...updatedState,
      };
    },
    setFilterState: (
      storeState,
      action: PayloadAction<{ categoryId: string; state: CategoryState }>
    ) => {
      const { categoryId, state } = action.payload;

      let updatedFilterState = { ...storeState.filterState };

      if (categoryId === 'scopeId' && updatedFilterState['groupId']) {
        updatedFilterState['groupId'] = {
          selectedOptions: [],
        };
      }

      updatedFilterState = {
        ...updatedFilterState,
        [categoryId]: state,
      };

      storeState.filterState = updatedFilterState;
    },
  },
});

export const {
  setSearchParams,
  setGridFilterState,
  setActiveGridTab,
  clearGridFilterState,
  setGridColumns,
  clearFilterState,
  removeFilterItem,
  replaceFilterState,
  setFilterState,
} = assetsSlice.actions;

export const getAssetsSearchParams = (state: RootState) =>
  state.assets.searchParams;

export const getActiveGridTab = (state: RootState): AssetsTabType =>
  state.assets.activeGridTab;

export const getGridFilterState = (state: RootState): MenuItems =>
  state.assets.gridFilterState;

export const getGridFilterSelections = (state: RootState): SelectionItems =>
  state.assets.gridFilterSelections;

export const getGridColumnsByAssetsTabType =
  (gridType: AssetsTabType | `${AssetsTabType}_nested`) =>
  (state: RootState): Array<ColDef | ColGroupDef> => {
    if (localStorage.getItem(AssetstoreLocalVariable.AssetsGridColumns)) {
      try {
        const storedGridColumns = JSON.parse(
          localStorage.getItem(
            AssetstoreLocalVariable.AssetsGridColumns
          ) as string
        );

        const gridColumns = storedGridColumns[gridType] || [];
        const stateGridColumns = state.assets.gridColumns[gridType] || [];

        if (stateGridColumns.length > gridColumns.length) {
          return stateGridColumns;
        }

        return gridColumns.map((gridColumn: ColDef) => {
          const stateGridColumn: ColDef = stateGridColumns.find(
            (stateGridColumn: ColDef) =>
              stateGridColumn.field === gridColumn.field
          ) as ColDef;

          return {
            ...gridColumn,
            cellRenderer: stateGridColumn && stateGridColumn?.cellRenderer,
          };
        });
      } catch (err) {
        return state.assets.gridColumns[gridType] || [];
      }
    } else {
      return state.assets.gridColumns[gridType] || [];
    }
  };

export const getFilterState = (state: RootState) => state.assets.filterState;

const assetsReducer = assetsSlice.reducer;
export default assetsReducer;
