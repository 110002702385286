import {
  FindingSeverity,
  FindingSeverityList,
} from 'FindingDetails/interfaces/severity';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { SelectChipCategory } from 'shared/components/SelectChipGroup/SelectChipGroup';
import { NotificationRuleTrigger } from 'shared/models/data/notification-rule.model';
import { capitalize } from 'lodash';
import * as yup from 'yup';
import { businessUnitEnvironmentTypesOptionList } from './business-unit.data';
import { useFetchFilterInformationForFieldMutation } from 'Risk/store/api';
import {
  ExtendedFilterCategory,
  FilterOperator,
  FilterOption,
  FilterType,
  OptionSourceType,
} from 'shared/models/data/data-filter.model';
import { StaticFilterOptions } from 'shared/components/FilterDropdown/FilterDropdown';
import { DataFitlerHandler } from 'shared/handlers/data-filter.handler';
import { RuleCategory } from 'Settings/interfaces/RiskCustomizationConfig';
import { DuplicationState } from 'Risk/interfaces/DuplicationState.enum';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { DataFilterOption } from 'shared/models/data/data-filters.model';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';

const dataFilterHandler = new DataFitlerHandler();

const booleanRiskFilterOptions: Array<FilterOption> = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const notificationRuleTriggerOptions: Array<AutocompleteOption> = [
  {
    value: NotificationRuleTrigger.FINDING_CLOSED,
    label: 'Finding closed',
  },
  {
    value: NotificationRuleTrigger.FINDING_CREATED,
    label: 'Finding opened',
  },
  {
    value: NotificationRuleTrigger.FINDING_SUPPRESSED,
    label: 'Finding suppressed',
  },
  {
    value: NotificationRuleTrigger.FINDING_IN_PROGRESS,
    label: 'Finding in-progress',
  },
  {
    value: NotificationRuleTrigger.FINDING_IN_REVIEW,
    label: 'Finding in-review',
  },
];

export const notificationRuleTicketTriggerOptions: Array<AutocompleteOption> = [
  {
    value: NotificationRuleTrigger.TICKET_CREATED,
    label: 'Ticket created',
  },
];

export const notificationRuleExceptionTriggerOptions: Array<AutocompleteOption> =
  [
    {
      value: NotificationRuleTrigger.EXCEPTION_REQUESTED,
      label: 'Exception Requested',
    },
    {
      value: NotificationRuleTrigger.EXCEPTION_APPROVED,
      label: 'Exception Approved',
    },
    {
      value: NotificationRuleTrigger.EXCEPTION_EXPIRED,
      label: 'Exception Expired',
    },
  ];

export const notificationRuleSLATriggerOptions: Array<AutocompleteOption> = [
  {
    value: NotificationRuleTrigger.FINDING_SLA_WARNING,
    label: 'finding nearly breaches SLA',
  },
  {
    value: NotificationRuleTrigger.FINDING_SLA_BREACHED,
    label: 'finding breaches SLA',
  },
];

export const notificationRuleStatusItems: Array<SelectChipCategory> = [
  {
    id: 'status',
    label: 'Status',
    loaded: true,
    optionList: notificationRuleTriggerOptions,
  },
];

const riskScoreOptionList = ['0-3', '3-6', '6-10'];

export const notificationRuleConditionOptions: Array<ExtendedFilterCategory> = [
  {
    id: RuleCategory.FINDING,
    label: 'Operational',
    categories: [
      {
        id: 'duplicationState',
        label: 'Duplication State',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: [
              {
                value: DuplicationState.PRIMARY,
                label: 'Duplicated',
              },
              {
                value: DuplicationState.SINGLE,
                label: 'Single',
              },
            ],
          },
        },
      },
      {
        id: 'labels',
        label: 'Labels',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'labels/filters/options?fieldName=label&orderBy=label&orderType=ASC&skip=0&take=10000',
            method: 'GET',
          },
        },
        operator: FilterOperator.OVERLAPS,
      },
    ],
  },
  {
    id: RuleCategory.FINDING,
    label: 'Risk',
    categories: [
      {
        id: 'title',
        label: 'Risk Name',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'config/filters/search/title',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'riskScore',
        label: 'Risk Score',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 10,
        },
      },
      {
        id: 'findingType',
        label: 'Domain',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/findingType',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'category',
        label: 'Risk Category',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/category',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'severity',
        label: 'Severity',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/severity',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          sortMethod: (options) => options,
        },
      },
      {
        id: 'findingSource',
        label: 'Event Source',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/findingSource',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
    ],
  },
  {
    id: RuleCategory.ORGANIZATION,
    label: 'Organization',
    categories: [
      {
        id: 'scope',
        label: 'Scope',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'organization-structures/getUserAvailableNodesOfType',
            body: {
              nodesType: 'scope',
            },
            optionsTransformer: (options: Array<OrganizationNode>) => {
              return options.map((option: OrganizationNode) => ({
                value: option.id,
                label: option.name,
              }));
            },
          },
        },
      },
      {
        id: 'group',
        label: 'Group',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'organization-structures/getUserAvailableNodesOfType',
            body: {
              nodesType: 'logical',
            },
            optionsTransformer: (options: Array<OrganizationNode>) => {
              return options.map((option: OrganizationNode) => ({
                value: option.id,
                label: option.name,
              }));
            },
          },
        },
      },
      {
        id: 'businessUnitId',
        label: 'Service',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/businessUnitId',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          extraOptions: [
            {
              value: null,
              label: StaticFilterOptions.NONE,
            },
          ],
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
      {
        id: `cloudWorkspaceId`,
        label: 'Workspace',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/workspace',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          extraOptions: [
            {
              value: null,
              label: StaticFilterOptions.NONE,
            },
          ],
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
      {
        id: 'environmentType',
        label: 'Environment',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/businessUnitEnvironmentType',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
    ],
  },
  {
    id: RuleCategory.INTELLIGENCE,
    label: 'Intelligence',
    entitlement: SystemFeatureFlag.FINDING_VIEW_INTELLIGENCE_TAB,
    categories: [
      {
        id: 'isDiscussed',
        label: 'Is Discussed',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasPOC',
        label: 'POC',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasExploit',
        label: 'Has Exploit',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'isExploitedInTheWild',
        label: 'In the Wild',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasPenTesterFramework',
        label: 'Pentest Framework',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'cisaKev',
        label: 'Cisa Kev',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasRansomware',
        label: 'Ransomware',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasThreatActor',
        label: 'Threat Actors',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'isTrending',
        label: 'Trending',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasMalware',
        label: 'Malware',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasPatch',
        label: 'Has Patch',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'isPrioritized',
        label: 'Prioritized',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'intelligenceScore',
        label: 'Intelligence Score',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 10,
        },
      },
      {
        id: 'epssScore',
        label: 'EPSS Score',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 1,
        },
      },
      {
        id: 'epssPercentile',
        label: 'EPSS Percentile',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 1,
        },
      },
    ],
  },
  {
    id: RuleCategory.RESOURCE,
    label: 'Resource',
    categories: [
      {
        id: 'resourceType',
        label: 'Resource Type',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/resourceType',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
    ],
  },
];

export const inRangePropertyNames = [
  'intelligenceScore',
  'epssScore',
  'epssPercentile',
  'riskScore',
];

export const hasAccessPropertyNames = ['group', 'scope'];

export const notificationRuleFormRequiredFields = ['name', 'email'];

export const notificationRuleSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  properties: yup.array().required(),
  triggers: yup.array().required(),
  emails: yup.array().required(),
  project: yup.string(),
});
