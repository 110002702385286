import { AutocompleteOption } from 'FindingDetails/store/api';

export enum ReportRuleStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum ReportRulePropertyType {
  SCOPE = 'Scope',
  GROUP = 'Group',
  SERVICE = 'Service',
  DOMAIN = 'Domain',
}

export enum ReportRuleRecipientType {
  EMAIL = 'Email',
  GROUP_OWNERS = 'Group Owners',
}

export enum ReportRulePropertyOperator {
  IN = 'IN',
}

export enum ReportRuleActionType {
  EMAIL = 'EMAIL',
}

export enum ReportRuleContentGroupType {
  RISK = 'RISK',
  COMPARATIVE_PER_SERVICE = 'COMPARATIVE_PER_SERVICE',
  COMPARATIVE_PER_GROUP = 'COMPARATIVE_PER_GROUP',
}

export enum ReportRuleContentWidgetId {
  ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_STATUS = 'accumulative_open_findings_by_sla_status',
  ACCUMULATIVE_OPEN_FINDINGS_BY_SEVERITY = 'accumulative_open_findings_by_severity',
  ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_BREACH_AGE = 'accumulative_open_findings_by_sla_breach_age',
  TOTAL_METRICS = 'total_metrics',
  TRENDS_METRICS = 'trends_metrics',
  TRENDS_GRAPH = 'trends_graph',
  FINDING_BY_STATUS = 'finding_by_status',
  FINDING_BY_SEVERITY = 'finding_by_severity',
  FINDING_BY_CATEGORY = 'finding_by_category',
  TOP_ASSIGNEES = 'top_assignees',
  FINDING_BY_TICKET_STATUS = 'finding_by_ticket_status',
  FINDING_BY_TICKET_AGE = 'finding_by_ticket_age',
  ACTIVE_CAMPAIGNS = 'active_campaigns',
}

export const operationalOverviewWidgetIds = [
  ReportRuleContentWidgetId.FINDING_BY_STATUS,
  ReportRuleContentWidgetId.TOP_ASSIGNEES,
  ReportRuleContentWidgetId.FINDING_BY_TICKET_AGE,
  ReportRuleContentWidgetId.FINDING_BY_TICKET_STATUS,
];

export enum ReportRuleFrequencyType {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  QUARTERS = 'QUARTERS',
}

export enum ReportRuleType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

export interface ReportRuleEmailActionData {
  recipients?: Array<string>;
  roles?: Array<string>;
}

export type ReportRuleActionData = ReportRuleEmailActionData;

export interface ReportRuleProperty {
  type: ReportRulePropertyType;
  operator: ReportRulePropertyOperator;
  values: any[];
}

export interface ReportRuleAction {
  type: ReportRuleActionType;
  data?: ReportRuleActionData;
}

export interface ReportRuleContentGroupElement {
  id: string;
  label?: string;
}

export interface ReportRuleContentGroupConfiguration {
  timeFrame: any;
  trendMetricsTypes: Array<any>;
}

export interface ReportRuleContentGroup {
  type: ReportRuleContentGroupType;
  label: string;
  elements: Array<ReportRuleContentGroupElement>;
  configuration?: ReportRuleContentGroupConfiguration;
}

export interface ReportRule {
  id?: string;
  tenantId?: string;
  name: string;
  description?: string;
  type: ReportRuleType;
  status: ReportRuleStatus;
  properties: Array<ReportRuleProperty>;
  actions: Array<ReportRuleAction>;
  contentGroups: Array<ReportRuleContentGroup>;
  frequencyType: ReportRuleFrequencyType;
  frequencyUnit?: number;
}

export interface ReportRuleFormData {
  name: string;
  description: string;
  recipients: AutocompleteOption;
  contentType: AutocompleteOption;
  widgets: Array<AutocompleteOption>;
  scopes: AutocompleteOption;
  groups: AutocompleteOption | Array<AutocompleteOption>;
  emails: Array<AutocompleteOption>;
  frequencyType: AutocompleteOption;
  timeFrame?: AutocompleteOption;
  trendMetricsTypes?: Array<AutocompleteOption>;
}
