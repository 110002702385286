import { IconButton, Tooltip } from '@mui/material';
import useCommonSelector from 'Common/utils/use-selector';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import {
  getSelectedGroup,
  getSelectedScope,
  selectdashboardFilter,
} from 'Dashboard/store';
import { FunctionComponent, useMemo } from 'react';
import CriticalityChip from 'shared/components/CriticalityChip';
import { IconContainer } from 'shared/components/IconContainer';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  BusinessUnitMetadata,
  BusinessUnitCloudProvider,
  BusinessUnitCloudWorkspace,
} from 'shared/models/data/business-unit.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useTranslation } from 'react-i18next';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import { GridType } from 'Risk/store';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { OrganizationalDataProps } from 'Dashboard/DashboardPage';
import { FindingState } from 'shared/fixtures/data/risk-grid.data';

interface BusinessUnitCarouselCardProps
  extends BaseComponentProps,
    BusinessUnitMetadata,
    OrganizationalDataProps {
  onSelect: (businessUnitId: Array<string>) => void;
}

const navigationHandler = new NavigationHandler();

export const BusinessUnitCarouselCard: FunctionComponent<
  BusinessUnitCarouselCardProps
> = ({
  id,
  cloudProviders,
  criticality,
  title,
  openIssues,
  childrenCount,
  onSelect,
  scopeData,
  groupData,
}) => {
  const { t: translation } = useTranslation();

  const selectedScope = useCommonSelector(getSelectedScope);
  const selectedGroup = useCommonSelector(getSelectedGroup);

  const selectedScopeItem = useMemo(() => {
    if (selectedScope.length === 0) return null;

    return scopeData?.find(
      (scopeDataItem) => scopeDataItem.id === selectedScope[0]
    );
  }, [selectedScope, scopeData]);

  const selectedGroupItem = useMemo(() => {
    if (selectedGroup.length === 0) return null;

    return groupData?.find(
      (groupDataItem) => groupDataItem.id === selectedGroup[0]
    );
  }, [selectedGroup, groupData]);

  const allFilter = useMemo(() => {
    return {
      ...(selectedScopeItem
        ? {
            scopeId: {
              id: selectedScopeItem?.id,
              name: selectedScopeItem?.name,
            },
          }
        : {}),
      ...(selectedGroupItem
        ? {
            groupId: {
              id: selectedGroupItem?.id,
              name: selectedGroupItem?.name,
            },
          }
        : {}),
    };
  }, [selectedScopeItem, selectedGroupItem]);

  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter
  );

  const riskNavigationUrl = useMemo<string>(() => {
    const businessUnitNavigationFilter: Record<
      string,
      FilterOption[] | FilterOption
    > =
      id === 'all'
        ? allFilter
        : {
            businessUnitId: [
              {
                id,
                name: title,
              },
            ] as Array<FilterOption>,
          };

    return navigationHandler.getRiskNavigationUrlWithParameters(GridType.None, {
      state: {
        id: FindingState.ACTIVE,
        name: FindingState.ACTIVE,
      },
      ...businessUnitNavigationFilter,
    });
  }, [dashboardFilter.businessUnitItems, allFilter]);

  const renderTooltipList = (labels: Array<string>) => {
    return (
      <div className="business-unit-carousel-card-tooltip-container">
        {labels.map((label: string) => (
          <div key={label} className="business-unit-carousel-card-tooltip-item">
            {label}
          </div>
        ))}
      </div>
    );
  };

  const renderCloudProviderIcons = () => {
    if (cloudProviders.length > 2) {
      const cloudProviderMoreItemsLabels: Array<string> = cloudProviders
        .slice(2, cloudProviders?.length)
        .map(
          (cloudProvider: BusinessUnitCloudProvider) =>
            `${cloudProvider.type} (${cloudProvider.accounts.length})`
        );

      return (
        <div className="business-unit-carousel-card-more-items-container">
          {cloudProviders &&
            cloudProviders.slice(0, 2).map((item: any, i: number) => {
              return (
                <Tooltip
                  title={
                    item.length
                      ? renderTooltipList(
                          item.accounts?.map(
                            (account: BusinessUnitCloudWorkspace) =>
                              account.alias
                          )
                        )
                      : ''
                  }
                  placement="right"
                >
                  <IconButton
                    classes={{
                      root: 'business-unit-carousel-card-icon-button',
                    }}
                  >
                    <IconContainer
                      icon={item.iconUrl}
                      badge={item.accounts?.length}
                    />
                  </IconButton>
                </Tooltip>
              );
            })}

          <Tooltip
            placement="right"
            title={
              cloudProviderMoreItemsLabels.length
                ? renderTooltipList(cloudProviderMoreItemsLabels)
                : ''
            }
          >
            <IconButton
              classes={{
                root: 'business-unit-carousel-card-more-items-indicator business-unit-carousel-card-icon-button',
              }}
            >
              {cloudProviderMoreItemsLabels.length}+
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    return cloudProviders.map((cloudProvider: BusinessUnitCloudProvider) => {
      const cloudWorkspaceLabels: Array<string> = cloudProvider.accounts?.map(
        (account: BusinessUnitCloudWorkspace) => account.alias
      );

      return (
        <Tooltip
          title={
            cloudWorkspaceLabels?.length
              ? renderTooltipList(cloudWorkspaceLabels)
              : ''
          }
          placement="right"
        >
          <IconButton
            classes={{ root: 'business-unit-carousel-card-icon-button' }}
          >
            <IconContainer
              icon={cloudProvider.iconUrl}
              badge={cloudProvider.accounts?.length}
            />
          </IconButton>
        </Tooltip>
      );
    });
  };

  const renderTitle = () => {
    if (childrenCount) return `${title} (${childrenCount})`;

    return title;
  };

  return (
    <div
      className={`business-unit-carousel-card-container ${
        (dashboardFilter.presentationalBusinessUnitId?.includes(id) ||
          (dashboardFilter.presentationalBusinessUnitId?.length === 0 &&
            id === 'all')) &&
        'business-unit-carousel-card-container-active'
      }`}
      onClick={() => {
        onSelect([id]);
      }}
    >
      <div className="business-unit-carousel-card-header">
        <div className="business-unit-carousel-card-cloud-providers">
          {renderCloudProviderIcons()}
        </div>
        {criticality && (
          <div className="business-unit-carousel-card-cloud-severity">
            <CriticalityChip criticality={criticality} />
          </div>
        )}
      </div>
      <div className="business-unit-carousel-card-footer">
        <div className="business-unit-carousel-card-title">{renderTitle()}</div>
        <a
          className="business-unit-carousel-card-description"
          href={riskNavigationUrl}
          target="_blank"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.BRAKE_WARNING_ICON} />
          <span>{`${openIssues} ${translation(
            `dashboards.cards.openFindings`
          )}`}</span>
        </a>
      </div>
    </div>
  );
};
