import { Button, CircularProgress } from '@mui/material';
import { OrderState } from 'Common/utils/sort';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { getAllColumnDefs } from 'shared/fixtures/data/risk-grid.data';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { NoDataBackdrop } from 'shared/components/NoDataBackdrop/NoDataBackdrop';
import {
  ColumnMovedEvent,
  ColumnVisibleEvent,
  RowClickedEvent,
} from 'ag-grid-community';

import {
  assetsDefaultGridColumns,
  assetsOnPremHiddenGridColumns,
  assetsViewFilterCategories,
  assetsonPremGridColumns,
  getAssetSearchParamsRequestDependencies,
} from 'shared/fixtures/data/assets-grid.data';
import { AssetsTabType } from 'Assets/interfaces/AssetsTabType';
import {
  getFilterState,
  getAssetsSearchParams,
  setGridColumns,
  setSearchParams,
  getGridColumnsByAssetsTabType,
} from 'Assets/store';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import { useSearchAssetsMutation } from 'Assets/store/api';

interface AssetsOnPremGridProps extends BaseComponentProps {
  clearFilters: () => void;
  onRowClicked?: (event: RowClickedEvent) => void;
  gridRef?: any;
}

const riskSearchParamsHandler = new FilterSearchParamsHandler();
const advanceFilterDataHandler = new AdvanceFilterHandler();

export const AssetsOnPremGrid: FunctionComponent<AssetsOnPremGridProps> = ({
  clearFilters,
  gridRef,
  onRowClicked,
}) => {
  let [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const orderParams = useMemo<OrderState | null>(() => {
    try {
      if (urlSearchParams.order) {
        const parsedOrderParams = JSON.parse(
          urlSearchParams.order as string
        ) as OrderState;

        return parsedOrderParams;
      }

      return null;
    } catch (err) {
      return null;
    }
  }, [urlSearchParams.order]);

  const searchParams = useCommonSelector(getAssetsSearchParams);

  const filterState = useCommonSelector(getFilterState);

  const domainGridColumns = useCommonSelector(
    getGridColumnsByAssetsTabType(AssetsTabType.ONPREM)
  );
  const dispatch = useCommonDispatch();

  const [searchAssets, { data: assetsPayload, isLoading: isRiskGroupLoading }] =
    useSearchAssetsMutation();

  const additionalSearchParams = useMemo(() => {
    return advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
      filterState,
      assetsViewFilterCategories
    );
  }, [filterState]);

  useEffect(() => {
    searchAssets({
      ...searchParams,
      filter: {
        ...searchParams.filter,
        layer: AssetsTabType.ONPREM,
        ...additionalSearchParams,
      },
      order: orderParams,
    });
  }, [
    ...getAssetSearchParamsRequestDependencies(
      additionalSearchParams,
      assetsViewFilterCategories
    ),
    orderParams,
    searchParams?.skip,
    searchParams?.take,
    searchParams?.filter?.nameKeyword,
  ]);

  const onPageChange = (pageNumber: number) => {
    dispatch(
      setSearchParams({
        ...searchParams,
        skip: searchParams.take * (pageNumber - 1),
      })
    );
  };

  const onPageSizeChange = (pageSize: number) => {
    dispatch(
      setSearchParams({
        ...searchParams,
        take: pageSize,
        skip: 0,
      })
    );
  };

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    const existingParams = riskSearchParamsHandler.setSearchParamsForKeys(
      urlSearchParams,
      ['activeTabId', 'filter', 'searchKeyword']
    );

    if (columnOrderPayload.length) {
      setUrlSearchParams({
        ...existingParams,
        order: JSON.stringify({
          field: columnOrderPayload[0].field,
          type: columnOrderPayload[0].type,
        }),
      });
    } else {
      delete existingParams.order;

      setUrlSearchParams({
        ...existingParams,
      });
    }
  };

  return (
    <CommonSimpleDataGrid
      columnDefs={domainGridColumns}
      isLoading={isRiskGroupLoading}
      rowData={assetsPayload?.data}
      visibilityControlProps={{
        enableVisibilityControls: true,
        columns: assetsOnPremHiddenGridColumns,
      }}
      defaultColDef={{
        resizable: true,
        sortable: true,
      }}
      otherComponents={{
        NoDataBackdropComponent: (
          <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
        ),
      }}
      getRowId={(params) => params.data?.assetUniqueId}
      suppressRowClickSelection
      gridRef={gridRef}
      loadingOverlayComponent={() => <CircularProgress />}
      paginationProps={{
        pageSize: searchParams.take,
        currentPage: searchParams.skip
          ? searchParams.skip / searchParams.take + 1
          : 1,
        onPageChange,
        onPageSizeChange,
        totalItems: assetsPayload?.totalItems || 0,
      }}
      sortModel={orderParams as OrderState}
      onColumnMoved={(event: ColumnMovedEvent) => {
        if (event.finished) {
          const updatedColumnDefs = event.api.getColumnDefs();

          dispatch(
            setGridColumns({
              gridType: AssetsTabType.ONPREM,
              columns: getAllColumnDefs(
                assetsonPremGridColumns,
                updatedColumnDefs || []
              ),
            })
          );
        }
      }}
      onColumnVisible={(event: ColumnVisibleEvent) => {
        const updatedColumnDefs = event.api.getColumnDefs();

        dispatch(
          setGridColumns({
            gridType: AssetsTabType.ONPREM,
            columns: getAllColumnDefs(
              assetsonPremGridColumns,
              updatedColumnDefs || []
            ),
          })
        );
      }}
      onSort={onHandleColumnOrderChange}
      onRowClicked={onRowClicked}
    />
  );
};
