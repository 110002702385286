import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import ImpactScoreChip from 'shared/components/ImpactScoreChip';
import { ElementWithLabel } from 'FindingDetails/components/FindingContent/components/FindingContentHeader/FindingContentHeader';
import { Box, Button, Skeleton, Tooltip } from '@mui/material';
import CopyLinkButton from 'shared/components/CopyLinkButton/CopyLinkButton';
import ActionButton from 'shared/components/ActionButton';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import AddOwnerModal from 'FindingDetails/components/FindingContent/components/AddOwnerModal';
import { useTranslation } from 'react-i18next';
import { ItemType } from 'FindingDetails/components/FindingContent/components/AddOwnerModal/AddOwnerModal';
import { formatDate } from 'Common/utils/utilsFunction';
import SeverityBreakdown from 'shared/components/SeverityBreakdown/SeverityBreakdown';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import ItemLabelDropdown from 'FindingDetails/components/FindingContent/components/ItemLabelDropdown';
import { SystemLabelType } from 'shared/components/LabelManagementDropdown/LabelManagementDropdown';
import { useSearchLabelsMutation } from 'shared/store/api';
import { useSearchParams } from 'react-router-dom';
import LabelList from 'shared/components/LabelList';

interface ResourceAssetDetailsDrawerHeaderDataSource {
  name: string;
  logo: string;
}

export interface ResourceAssetDetailsDrawerHeaderData {
  title: string;
  logo: string;
  impactScore: number;
  findingsCount: number;
  criticalFindingsCount: number;
  highFindingsCount: number;
  mediumFindingsCount: number;
  sources: Array<ResourceAssetDetailsDrawerHeaderDataSource>;
}

interface ResourceAssetDetailsDrawerHeaderProps extends BaseComponentProps {
  resourceData: any;
  resourceDataLoading: boolean;
  resourceDataError: any;
}

export const ResourceAssetDetailsDrawerHeader: FunctionComponent<
  ResourceAssetDetailsDrawerHeaderProps
> = ({ resourceData, resourceDataLoading, resourceDataError }) => {
  const { t: translation } = useTranslation();
  const [isAddOwnerModalOpen, setIsAddOwnerModalOpen] =
    useState<boolean>(false);
  const [labelAnchorEl, setLabelAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();
  const [
    searchActiveLabelItems,
    { data: activeLabelItems, isLoading: activeLabelItemsLoading },
  ] = useSearchLabelsMutation();

  const impactScore = useMemo<number>(() => {
    return resourceData?.impactScore ? resourceData.impactScore : 0;
  }, [resourceData]);

  const totalFindings = useMemo<number>(() => {
    return Object.values(resourceData?.severityBreakdown || {}).reduce<number>(
      (sum, value) => sum + (value as number),
      0
    );
  }, [resourceData?.severityBreakdown]);

  useEffect(() => {
    if (resourceData?.assetUniqueId)
      searchActiveLabelItems({
        itemType: SystemLabelType.ASSET,
        itemId: resourceData?.assetUniqueId,
      });
  }, [resourceData?.assetUniqueId]);

  if (resourceDataLoading)
    return (
      <Box className="main-content-skeleton">
        <Skeleton variant="rectangular" height={100} width={'100%'} />{' '}
      </Box>
    );

  if (resourceDataError) return <></>;

  const renderSources = () => {
    return resourceData?.relatedApplicationIds?.length ? (
      <>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="Sources">
          <div className="resource-asset-details-sources-container">
            {resourceData?.relatedApplicationIds?.map((source: any) => (
              <div className="resource-asset-details-sources-item">
                <OpusImageIcon
                  src={source.iconUrl}
                  className="application-logo"
                  key={source.id}
                  title={source.id}
                />
              </div>
            ))}
          </div>
        </ElementWithLabel>
      </>
    ) : (
      <></>
    );
  };

  const renderSeverityChips = () => {
    const severityBreakdown = resourceData?.severityBreakdown;

    if (!severityBreakdown) {
      return null;
    }
    return (
      <>
        <div className="finding-content-dot"></div>
        <SeverityBreakdown severityData={severityBreakdown} />
      </>
    );
  };

  const renderResourceTitleRow = () => {
    if (resourceDataLoading)
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={40} width={'100%'} />{' '}
        </div>
      );

    return (
      <div className="finding-content-header-title-row">
        <Tooltip title={''}>
          <div className="finding-content-header-icon">
            <img src={resourceData?.typeLogoUrl} alt={resourceData?.type} />
          </div>
        </Tooltip>
        <div className="finding-content-header-title">{resourceData?.name}</div>
        <CopyLinkButton
          excludeSearchParams={['filter', 'order', 'searchKeyword']}
        />
      </div>
    );
  };
  const handleAddOwner = () => {
    setIsAddOwnerModalOpen(true);
  };

  const renderLabelList = () => {
    if (activeLabelItemsLoading) return <Skeleton height={50} />;

    if (activeLabelItems?.length) return <LabelList items={activeLabelItems} />;

    return <></>;
  };

  return (
    <div className="finding-content-header">
      {renderResourceTitleRow()}
      <div className="finding-content-header-elements">
        <ElementWithLabel label="# Findings">
          <div className="resource-asset-details-findings-count">
            {totalFindings}
          </div>
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="Impact Score">
          <ImpactScoreChip value={+Number(impactScore).toFixed(2)} />
        </ElementWithLabel>
        {renderSources()}
        {renderSeverityChips()}
      </div>
      {renderLabelList()}

      <div className="finding-content-header-actions">
        <ActionButton
          icon={SVG_ICON_TYPES.USER_ADD_ICON}
          onClick={handleAddOwner}
        >
          {translation(`findings.details.addOwner`)}
        </ActionButton>
        <Button
          className="add-label-button"
          onClick={(event) => {
            setLabelAnchorEl(event.currentTarget);
          }}
        >
          <div className="add-label-button-text">
            <OpusSvgIcon type={SVG_ICON_TYPES.TAGS_ICON} />
            <span>{translation(`labels.title`)}</span>
          </div>
          <div className="add-label-button-end-andornment">
            <OpusSvgIcon type={SVG_ICON_TYPES.SORT_DESCENDING_ICON} />
          </div>
        </Button>

        <div className="updates-info-container">
          <ElementWithLabel
            label={translation(`common.lastSeen`)}
            icon={SVG_ICON_TYPES.SIMPLE_CALENDAR_ICON}
          >
            <p className="text-display">
              {formatDate(resourceData?.lastSeenAt, false)}
            </p>
          </ElementWithLabel>
          <ElementWithLabel
            label={translation(`findings.details.firstSeen`)}
            icon={SVG_ICON_TYPES.VISIBILITY_ICON}
          >
            <p className="text-display">
              {formatDate(resourceData?.firstSeenAt, false)}
            </p>
          </ElementWithLabel>
        </div>
      </div>
      <AddOwnerModal
        id={resourceData?.assetUniqueId}
        type={ItemType.ASSET}
        isOpen={isAddOwnerModalOpen}
        setIsOpen={setIsAddOwnerModalOpen}
      ></AddOwnerModal>
      <ItemLabelDropdown
        open={Boolean(labelAnchorEl)}
        handleClose={() => {
          setLabelAnchorEl(null);
        }}
        anchorEl={labelAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        itemId={resourceData?.assetUniqueId || ''}
        itemType={SystemLabelType.ASSET}
        saveSuccessCallback={() => {
          searchActiveLabelItems({
            itemType: SystemLabelType.ASSET,
            itemId: resourceData?.assetUniqueId,
          });
        }}
      />
    </div>
  );
};
