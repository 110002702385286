import { Button } from '@mui/material';
import {
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import {
  CategoryState,
  ExtendedFilterCategory,
  FilterCategory,
  FilterType,
  KeyValueState,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import CategoryDropdown from '../CategoryDropdown';
import { FilterChip } from '../FilterChip/FilterChip';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';

export interface FilterChipGroupChangeHandlerProps {
  categoryState: Record<string, CategoryState>;
  onChange: (categoryId: string, categoryState: CategoryState) => void;
  onRemoveChip?: (categoryId: string) => void;
  onClearChipState?: (categoryId: string) => void;
}

export interface FilterChipGroupProps
  extends BaseComponentProps,
    FilterChipGroupChangeHandlerProps {
  categories: Array<ExtendedFilterCategory>;
  staticCategoryIds?: Array<string>;
  disableAddButton?: boolean;
  addButtonAlignment?: 'left' | 'right';
  Components?: {
    AddButtonContent?: ReactNode;
  };
  separator?: string;
}

const advancedFilterDataHandler = new AdvanceFilterHandler();

export const FilterChipGroup: FunctionComponent<FilterChipGroupProps> = ({
  categories,
  categoryState,
  onChange,
  staticCategoryIds,
  disableAddButton = false,
  onRemoveChip,
  onClearChipState,
  addButtonAlignment = 'left',
  Components,
  separator,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const staticCategories = useMemo<Array<FilterCategory>>(() => {
    if (staticCategoryIds?.length) {
      const flatCategories =
        advancedFilterDataHandler.extractAllCategories(categories);

      return staticCategoryIds
        .map((id) => flatCategories.find((category) => category.id === id))
        .filter((category): category is FilterCategory => !!category)
        .map((category: FilterCategory) => {
          const categoryStateItem = categoryState[category.id];

          return {
            ...category,
            state: {
              ...category.state,
              ...(categoryStateItem || {}),
            },
          };
        });
    }

    return [];
  }, [categories, staticCategoryIds, categoryState]);

  const dynamicCategories = useMemo<Array<FilterCategory>>(() => {
    const activeCategoryIds = Object.keys(categoryState);

    const dynamicActiveCategoryIds = activeCategoryIds.filter(
      (id: string) => !staticCategoryIds?.includes(id)
    );

    if (dynamicActiveCategoryIds?.length) {
      const allCategories =
        advancedFilterDataHandler.extractAllCategories(categories);

      return allCategories
        .filter((category: FilterCategory) =>
          dynamicActiveCategoryIds.includes(category.id)
        )
        .filter((category: FilterCategory) => {
          if (category.type === FilterType.KEY_VALUE) {
            const typedState = categoryState[category.id] as KeyValueState;

            return typedState.selections?.some(
              (selection) => selection.key.value.length > 0
            );
          }

          return true;
        })
        .map((category: FilterCategory) => {
          const categoryStateItem = categoryState[category.id];

          return {
            ...category,
            state: {
              ...category.state,
              ...(categoryStateItem || {}),
            },
          };
        });
    }

    return [];
  }, [categories, staticCategoryIds, categoryState]);

  const handleAddButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCategoryPopoverClose = () => {
    setAnchorEl(null);
  };

  const renderAddFilterButton = () => {
    if (disableAddButton) return <></>;

    return (
      <Button className="add-filter-button" onClick={handleAddButtonClick}>
        {Components?.AddButtonContent || (
          <>
            <OpusSvgIcon type={SVG_ICON_TYPES.PLUS_ICON} />
            <span>Add Filter</span>
          </>
        )}
      </Button>
    );
  };

  const renderStaticChips = () => {
    return staticCategories.map(
      (
        category: FilterCategory,
        index: number,
        categoryList: Array<FilterCategory>
      ) => {
        return (
          <>
            <FilterChip
              {...category}
              categoryState={categoryState}
              onChange={onChange}
              categories={category.categories}
              onRemoveChip={onClearChipState}
            />
            {separator && index !== categoryList.length - 1 ? (
              <span>{separator}</span>
            ) : (
              <></>
            )}
          </>
        );
      }
    );
  };

  const renderDynamicChips = () => {
    return dynamicCategories.map(
      (
        category: FilterCategory,
        index: number,
        categoryList: Array<FilterCategory>
      ) => {
        return (
          <>
            <FilterChip
              {...category}
              categoryState={categoryState}
              onChange={onChange}
              categories={category.categories}
              onRemoveChip={onRemoveChip}
            />
            {separator && index !== categoryList.length - 1 ? (
              <span>{separator}</span>
            ) : (
              <></>
            )}
          </>
        );
      }
    );
  };

  const renderSeparator = () => {
    if (separator === undefined) return <></>;

    if (staticCategories.length === 0 || dynamicCategories.length === 0)
      return <></>;

    return <span>{separator}</span>;
  };

  return (
    <>
      <div className="filter-chip-group-container">
        {addButtonAlignment === 'left' ? renderAddFilterButton() : <></>}
        {renderStaticChips()}
        {renderSeparator()}
        {renderDynamicChips()}
        {addButtonAlignment === 'right' ? renderAddFilterButton() : <></>}
      </div>
      <CategoryDropdown
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        categories={categories?.sort((categoryA, categoryB) =>
          categoryA.label.localeCompare(categoryB.label)
        )}
        categoryState={categoryState}
        handleClose={handleCategoryPopoverClose}
        onChange={onChange}
      />
    </>
  );
};
