import { CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { ApplicationLogo } from 'Application/interfaces/item';
import CardWrapper from 'Common/components/CardWrapper';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import useCommonSelector from 'Common/utils/use-selector';
import {
  RiskAssessmentSDLCWidgetItem,
  RiskAssessmentSDLCWidgetItemData,
  RiskAssessmentSeverity,
} from 'Dashboard/interfaces/RiskAssessmentSDLCWidgetItem.model';
import { selectdashboardFilter } from 'Dashboard/store';
import {
  FindingSeverity,
  SeverityTextMapper,
} from 'FindingDetails/interfaces/severity';
import { GridType } from 'Risk/store';
import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonSeverityChip from 'shared/components/CommonSeverityChip';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import _ from 'lodash';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { NumberFormatHandler } from 'shared/handlers/number-format.handler';
import { FindingState } from 'shared/fixtures/data/risk-grid.data';
import SeverityBreakdown from 'shared/components/SeverityBreakdown/SeverityBreakdown';

interface RiskAssessmentCardProps extends RiskAssessmentSDLCWidgetItem {
  isLoading?: boolean;
  widgetData?: any;
  scopeData?: Array<OrganizationNode>;
  groupData?: Array<OrganizationNode>;
}

const navigationHandler = new NavigationHandler();

const numberFormatHandler = new NumberFormatHandler();

export const RiskAssessmentCard: React.FC<RiskAssessmentCardProps> = ({
  title,
  type,
  icon,
  isLoading,
  widgetData,
  findingTypes,
  scopeData,
  groupData,
}) => {
  const [sdlcData, setSdlcData] =
    useState<RiskAssessmentSDLCWidgetItemData | null>();

  const dashboardFilter = useCommonSelector(selectdashboardFilter);

  const businessUnitNavigationFilter = useMemo<
    Partial<{ businessUnitId: Array<FilterOption> }>
  >(() => {
    const selectedServices = dashboardFilter.businessUnitItems?.filter(
      (businessUnitItem) => {
        return dashboardFilter.selectedServices.find(
          (serviceId) => businessUnitItem.id === serviceId
        );
      }
    );

    return dashboardFilter.presentationalBusinessUnitId?.includes('all')
      ? selectedServices?.length
        ? { businessUnitId: selectedServices }
        : {}
      : {
          businessUnitId: dashboardFilter.businessUnitItems,
        };
  }, [dashboardFilter.businessUnitId, dashboardFilter.selectedServices]);

  const groupNavigationFilter = useMemo<
    Partial<{ groupId: FilterOption }>
  >(() => {
    if (businessUnitNavigationFilter.businessUnitId) return {};

    const selectedGroup: OrganizationNode | undefined = groupData?.find(
      (groupDataItem: OrganizationNode) =>
        dashboardFilter.selectedGroup?.includes(groupDataItem.id)
    );

    return selectedGroup
      ? {
          groupId: {
            id: selectedGroup.id,
            name: selectedGroup.name,
          },
        }
      : {};
  }, [dashboardFilter.selectedGroup, groupData, businessUnitNavigationFilter]);

  const scopeNavigationFilter = useMemo<
    Partial<{ scopeId: FilterOption }>
  >(() => {
    if (businessUnitNavigationFilter.businessUnitId) return {};

    const selectedScope: OrganizationNode | undefined = scopeData?.find(
      (scopeDataItem: OrganizationNode) =>
        dashboardFilter.selectedScope?.includes(scopeDataItem.id)
    );

    return selectedScope
      ? {
          scopeId: {
            id: selectedScope.id,
            name: selectedScope.name,
          },
        }
      : {};
  }, [dashboardFilter.selectedScope, scopeData, businessUnitNavigationFilter]);

  useEffect(() => {
    if (widgetData) {
      setSdlcData(widgetData);
    }
  }, [widgetData, type, isLoading]);

  const renderTooltipContent = (appList?: ApplicationLogo[]) => {
    return (
      <Grid container className="risk-app-tooltip">
        {appList?.map((app) => (
          <Grid item xs={12} key={app.id} className="risk-app-item">
            <OpusImageIcon src={app.logo} className="application-logo" />
            <p className="label-4">{app.name}</p>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderApplicationsList = (applications?: ApplicationLogo[]) => {
    if (!applications?.length)
      return (
        <Grid
          item
          xs={12}
          display="flex"
          gap={1}
          className="applications-list"
        ></Grid>
      );
    return (
      <Grid item xs={12} display="flex" gap={1} className="applications-list">
        {applications?.slice(0, 3).map((item) => (
          <CardWrapper className="application-logo-container" key={item.id}>
            <OpusImageIcon src={item.logo} className="application-logo" />
          </CardWrapper>
        ))}
        {applications?.length > 3 && renderMoreApplicationsButton(applications)}
      </Grid>
    );
  };

  const renderMoreApplicationsButton = (applications: ApplicationLogo[]) => {
    return (
      <CardWrapper className="more-app-button" key={'more-app'}>
        <CommonTooltip
          placement="top"
          title={renderTooltipContent(applications?.slice(3))}
        >
          <p>+{applications?.slice(3).length}</p>
        </CommonTooltip>
      </CardWrapper>
    );
  };

  const renderSeverityList = (severityList?: RiskAssessmentSeverity[]) => {
    if (severityList?.length === 0) return <></>;
    const transformedSeverityData = severityList?.reduce((result, item) => {
      result[item.severity] = item.totalNumber;
      return result;
    }, {} as Partial<Record<FindingSeverity, number>>);
    return (
      <Grid item xs={12} display="flex">
        <SeverityBreakdown
          severityData={transformedSeverityData || {}}
          severityToDisplay={[
            FindingSeverity.HIGH,
            FindingSeverity.CRITICAL,
            FindingSeverity.MEDIUM,
          ]}
        />
      </Grid>
    );
  };

  if (!isLoading && !sdlcData) {
    return null;
  }
  return (
    <CardWrapper className="risk-assessment-dashboard-card">
      <Grid
        container
        className="risk-assessment-dashboard-card-container"
        columnSpacing={{ xs: 2, sm: 4, md: 3 }}
      >
        <Grid item xs={12} display="flex" gap={1} alignItems="center">
          <div className="title-icon">
            <OpusSvgIcon type={icon} />
          </div>
          <div
            className="title"
            onClick={() => {
              let commonFindingTypes =
                dashboardFilter.findingTypes.length > 0
                  ? _.intersection(dashboardFilter.findingTypes, findingTypes)
                  : findingTypes || [];

              if (
                commonFindingTypes.length === 0 &&
                sdlcData?.applications?.length
              ) {
                commonFindingTypes = findingTypes || [];
              }

              if (commonFindingTypes.length) {
                navigationHandler.handleRiskNavigation(GridType.None, {
                  state: {
                    id: FindingState.ACTIVE,
                    name: FindingState.ACTIVE,
                  },
                  findingType: commonFindingTypes?.map((findingType) => ({
                    id: findingType,
                    name: findingType,
                  })) as Array<FilterOption>,
                  ...businessUnitNavigationFilter,
                  ...scopeNavigationFilter,
                  ...groupNavigationFilter,
                });
              }
            }}
          >
            <Typography variant="inherit" className="label-1">
              {title}
            </Typography>
          </div>
        </Grid>
        {isLoading ? (
          <Grid item display="flex" textAlign={'center'} xs={12}>
            <CircularProgress size={18} />
          </Grid>
        ) : (
          <>
            {renderApplicationsList(sdlcData?.applications)}
            {renderSeverityList(sdlcData?.severityList)}
          </>
        )}
      </Grid>
    </CardWrapper>
  );
};
