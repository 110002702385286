import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DashboardReportsRiskWidgetProps } from '../DashboardReportsRisk/DashboardReportsRisk';
import { FunctionComponent, useEffect } from 'react';
import { useSearchCampaignListMutation } from 'Campaigns/store/api';
import ActiveCampaignCarouselList from 'Dashboard/components/ActiveCampaignCarouselList';
import ActiveCampaignCarouselCard from 'Dashboard/components/ActiveCampaignCarouselCard';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface DashboardReportsRiskActiveCampaignsProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps {}

export const DashboardReportsRiskActiveCampaigns: FunctionComponent<
  DashboardReportsRiskActiveCampaignsProps
> = ({ businessUnitIds, findingTypes }) => {
  const [
    getActiveCampaigns,
    { data: activeCampaigns, isLoading: activeCampaignsLoading },
  ] = useSearchCampaignListMutation();

  useEffect(() => {
    getActiveCampaigns({
      filter: {
        businessUnitsIds: businessUnitIds,
        findingType: findingTypes,
      },
    });
  }, [businessUnitIds, findingTypes]);

  return (
    <div className="dashboard-reports-risk-active-campaigns-widget-container dashboard-reports-risk-widget-container">
      <div className="dashboard-reports-risk-widget-title">
        <div className="dashboard-reports-risk-widget-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.BULLHORN_ICON} />
        </div>
        <div className="dashboard-reports-risk-widget-title-text">
          Active Campaigns ({activeCampaigns?.data?.length || 0})
        </div>
      </div>
      <div className="dashboard-reports-risk-widget-body dashboard-reports-risk-active-campaigns-widget-body">
        {activeCampaigns?.data?.map((activeCampaign) => (
          <ActiveCampaignCarouselCard {...activeCampaign} lazyLoading={false} />
        )) || <></>}
      </div>
    </div>
  );
};
