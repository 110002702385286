import { FunctionComponent, useEffect, useMemo } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DashboardReportsRiskWidgetProps } from '../DashboardReportsRisk/DashboardReportsRisk';
import { useGetDashboardAnalyticTrendsMutation } from 'Dashboard/store/api';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import { DashboardWidgetHandler } from 'shared/handlers/dashboard-widget.handler';
import { useTranslation } from 'react-i18next';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import { ChartOptions } from 'chart.js';
import SimpleLineChart from 'shared/components/SimpleLineChart';
import { ChartDataItem } from 'shared/models/data/chart.model';
import { OperationalWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import { TimelineFilter } from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import DashboardChartCard from 'Dashboard/components/DashboardChartCard';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useSearchParams } from 'react-router-dom';
import { AutocompleteOption } from 'FindingDetails/store/api';

interface DashboardReportsRiskTrendsWidgetProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps {
  timeFrame: TimelineFilter;
  trendMetricsTypes: Array<OperationalWidgetAnalyticsType>;
}

const dashboardWidgetHandler = new DashboardWidgetHandler();

export const DashboardReportsRiskTrendsWidget: FunctionComponent<
  DashboardReportsRiskTrendsWidgetProps
> = ({ businessUnitIds, timeFrame, trendMetricsTypes, findingTypes }) => {
  const { t: translation } = useTranslation();

  const addAnalyticsTrendDataLabels = () => {
    if (analyticTrendsWidgetData) {
      return {
        ...analyticTrendsWidgetData,
        datasets: analyticTrendsWidgetData.datasets.map(
          (dataset: ChartDataItem) => ({
            ...dataset,
            label: dashboardWidgetHandler.getOperationalWidgetTitle(
              translation,
              dataset.label as OperationalWidgetAnalyticsType
            ),
          })
        ),
      };
    }

    return analyticTrendsWidgetData;
  };

  const [
    getAnalitycTrendsWidgetData,
    {
      data: analyticTrendsWidgetData,
      isLoading: analyticTrendsDataWidgetLoading,
    },
  ] = useGetDashboardAnalyticTrendsMutation();

  const operationalWidgets =
    dashboardWidgetHandler.getOperationalWidgets(translation);

  useEffect(() => {
    getAnalitycTrendsWidgetData({
      businessUnitIds: businessUnitIds,
      findingTypes: findingTypes,
      timeline: timeFrame,
      types: trendMetricsTypes,
    });
  }, [businessUnitIds, timeFrame, trendMetricsTypes, findingTypes]);

  const renderChartWidget = (widgetData: any, isLoading: boolean) => {
    const hasNoData =
      (!widgetData || widgetData?.datasets.length === 0) && !isLoading;

    if (hasNoData) {
      return <NoDataToDisplayCard displayIcon={true} />;
    }

    const widgetIndexMap = new Map(
      operationalWidgets.map((widget, index) => [widget.title, index])
    );

    widgetData?.datasets.sort((a: any, b: any) => {
      const indexA = widgetIndexMap.get(a.label) ?? Infinity;
      const indexB = widgetIndexMap.get(b.label) ?? Infinity;

      return indexA - indexB;
    });

    const chartOptions = presentationalConfigurationForWidgets[
      'analytic_trends'
    ].options as ChartOptions<'line'>;

    return (
      <>
        <SimpleLineChart
          fetchWidgetData={() => {}}
          isWidgetDataLoading={isLoading}
          widgetData={widgetData}
          style={presentationalConfigurationForWidgets['analytic_trends'].style}
          options={chartOptions}
          customLegendContainerId="operational-dashboard-section-metrics-chart-legend-container"
          displayLegend
          enableTooltip
        />
        {!isLoading && (
          <div id="operational-dashboard-section-metrics-chart-legend-container"></div>
        )}
      </>
    );
  };

  return (
    <div className="dashboard-reports-risk-trends-widget-container dashboard-reports-risk-widget-container">
      <div className="dashboard-reports-risk-widget-title">
        <div className="dashboard-reports-risk-widget-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_CHECK_ICON} />
        </div>
        <div className="dashboard-reports-risk-widget-title-text">
          Trends Graph
        </div>
      </div>
      <div className="dashboard-reports-risk-widget-body dashboard-reports-risk-trends-widget-body">
        <DashboardChartCard
          title=""
          className="operational-dashboard-section-by-severity-chart-container-analytic-trends"
        >
          {renderChartWidget(
            addAnalyticsTrendDataLabels(),
            analyticTrendsDataWidgetLoading
          )}
        </DashboardChartCard>
      </div>
    </div>
  );
};
