import { Alert, Button } from '@mui/material';
import { AuthorizedContent } from '@frontegg/react';
import ActionButtons from 'Common/components/ActionButtons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import {
  changeAssigneeActionMenuItem,
  changeStatusActionMenuItem,
  reAssociateActionMenuItem,
  reMapActionMenuItem,
  reRunActionMenuItem,
  addLabelActionMenuItem,
} from 'shared/fixtures/data/action-menu-list.data';
import { GridSelectionDtoHandler } from 'shared/handlers/grid-selection-dto.handler';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { FilterActionButtonPayload } from '../FilterActions/FilterActions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ActionsMenuModal from '../ActionsMenuModal/ActionsMenuModal';
import { ActionMenuModalConfiguration } from 'shared/models/action-menu-modal-configuration';
import { useBulkFindingActionByTypeMutation } from 'Risk/store/api';
import { FindingBulkActionTypeEnum } from 'Risk/interfaces/FindingBulkActionType.enum';
import CommonSnackbarContainer from 'shared/components/CommonSnackbarContainer';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import { GridType } from 'Risk/store';
import LabelAddModal from 'shared/components/LabelAddModal';

export interface ActionsMenuProps {
  selectionProps: any;
  isDisabled: boolean;
  itemCount: number;
}
const gridSelectionDtoHandler = new GridSelectionDtoHandler();

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  selectionProps,
  isDisabled,
}) => {
  const userPermissions = usePermissions();

  const [
    bulkChangeFindings,
    {
      isLoading: isLoadingBulkChange,
      isError: bulkChangeFailed,
      isSuccess: bulkChangeSuccess,
      reset: resetBulkChange,
    },
  ] = useBulkFindingActionByTypeMutation();
  const { t: translation } = useTranslation();
  const selection = gridSelectionDtoHandler.transformGridSelectionPropsToBffDto(
    selectionProps.gridType,
    selectionProps
  );

  const [modalConfiguration, setModalConfiguration] =
    useState<ActionMenuModalConfiguration>({
      title: '',
      isOpen: false,
      errorMessage: '',
      findingsIds: selection.selectedFindingIds,
    });

  const [addLabelModalOpen, setAddLabelModalOpen] = useState<boolean>(false);

  const handleChangeAssigneeClick = () => {
    setModalConfiguration({
      isOpen: true,
      errorMessage: translation('findings.action.changeAssignee'),
      title: translation('findings.action.changeAssignee'),
      findingsIds: selection.selectedFindingIds,
      icon: changeAssigneeActionMenuItem.getIcon(),
      actionType: FindingBulkActionTypeEnum.ASSIGNEE_CHANGE,
    });
  };
  const handleChangeStatusClick = () => {
    setModalConfiguration({
      isOpen: true,
      errorMessage: translation('findings.action.changeStatus'),
      title: translation('findings.action.changeStatus'),
      findingsIds: selection.selectedFindingIds,
      icon: changeStatusActionMenuItem.getIcon(),
      actionType: FindingBulkActionTypeEnum.CHANGE_STATUS,
    });
  };

  const handleBulkClick = (event: any, type: FindingBulkActionTypeEnum) => {
    event.preventDefault();
    bulkChangeFindings({
      body: {
        selectionProps: selection,
        findings: [],
      },
      type: type,
    });
  };

  const buttons = useMemo<FilterActionButtonPayload>(() => {
    const defaultButtons: FilterActionButtonPayload = {
      [FindingBulkActionTypeEnum.CHANGE_STATUS]: {
        ...changeStatusActionMenuItem,
        handleClick: handleChangeStatusClick,
      },
      [FindingBulkActionTypeEnum.ASSIGNEE_CHANGE]: {
        ...changeAssigneeActionMenuItem,
        handleClick: handleChangeAssigneeClick,
      },
    };

    if (selectionProps.gridType !== GridType.RootCause) {
      defaultButtons[FindingBulkActionTypeEnum.RERUN] = {
        ...reRunActionMenuItem,
        handleClick: (event: any) =>
          handleBulkClick(event, FindingBulkActionTypeEnum.RERUN),
      };
      defaultButtons[FindingBulkActionTypeEnum.REMAP] = {
        ...reMapActionMenuItem,
        handleClick: (event: any) =>
          handleBulkClick(event, FindingBulkActionTypeEnum.REMAP),
      };
      defaultButtons[FindingBulkActionTypeEnum.REASSOCIATE] = {
        ...reAssociateActionMenuItem,
        handleClick: (event: any) =>
          handleBulkClick(event, FindingBulkActionTypeEnum.REASSOCIATE),
      };
      defaultButtons[FindingBulkActionTypeEnum.ADD_LABEL] = {
        ...addLabelActionMenuItem,
        handleClick: () => {
          setAddLabelModalOpen(true);
        },
      };
    }

    return defaultButtons;
  }, [selectionProps]);

  const buttonComponents = Object.values(buttons).reduce(
    (
      acc: JSX.Element[],
      { id, label, getIcon, disabled, permissions, handleClick }
    ) => {
      const hasPermission = (permissions || []).every((permission) =>
        userPermissions.includes(permission)
      );
      if (hasPermission) {
        const icon = getIcon ? getIcon() : null;
        const buttonElement = (
          <AuthorizedContent
            key={id}
            requiredPermissions={permissions || []}
            render={(isAuthorized) => (
              <Button
                startIcon={icon}
                className="risk-action-base-button"
                disabled={disabled || !isAuthorized}
                onClick={handleClick}
              >
                {label}
              </Button>
            )}
          />
        );
        acc.push(buttonElement);
      }
      return acc;
    },
    []
  );

  return (
    <>
      <ActionButtons
        baseButton={
          <Button
            className="action-base-button open-ticket"
            startIcon={<OpusSvgIcon type={SVG_ICON_TYPES.EDIT_ICON} />}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={Object.keys(buttons).length === 0 || isDisabled}
          >
            <FormattedMessage id="risk.actions" defaultMessage="Actions" />
          </Button>
        }
        options={buttonComponents}
        classes={{
          menu: 'risk-actions-buttons-menu',
        }}
      />
      <ActionsMenuModal
        handleModalClose={() => {
          setModalConfiguration({
            ...modalConfiguration,
            isOpen: false,
          });
        }}
        selection={selection}
        configuration={modalConfiguration}
      />

      <LabelAddModal
        open={addLabelModalOpen}
        onClose={() => {
          setAddLabelModalOpen(false);
        }}
        selection={selection}
      />
      <CommonSnackbarContainer
        open={isLoadingBulkChange}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => resetBulkChange()}
      >
        <Alert severity="info">
          {translation('findings.action.bulkActionProgress')}
        </Alert>
      </CommonSnackbarContainer>
      <CommonSnackbarContainer
        open={bulkChangeFailed}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={3000}
        onClose={() => resetBulkChange()}
      >
        <Alert severity="error">
          {translation('findings.action.bulkActionFailed')}
        </Alert>
      </CommonSnackbarContainer>
      <CommonSnackbarContainer
        open={bulkChangeSuccess}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={3000}
        onClose={() => resetBulkChange()}
      >
        <Alert severity="success">
          {translation('findings.action.bulkActionSuccess')}
        </Alert>
      </CommonSnackbarContainer>
    </>
  );
};
