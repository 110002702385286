import {
  CategoryState,
  ExtendedFilterCategory,
} from 'shared/models/data/data-filter.model';
import * as yup from 'yup';
import { ConfigurationFieldValue } from 'shared/components/MultiSelectConfigurationField/MultiSelectConfigurationField';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { notificationRuleConditionOptions } from './notification-rule.data';

export type SlaRuleBaseFormData = Record<'name' | 'description', string>;

export type SlaRuleAdditionalFormData = {
  properties: Record<string, CategoryState>;
  values: Array<ConfigurationFieldValue>;
};

export type SlaRuleFormData = SlaRuleBaseFormData & SlaRuleAdditionalFormData;

export const slaRuleSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  properties: yup.array().required(),
  values: yup.object().required(),
});

export const slaRuleInRangePropertyNames = [
  'intelligenceScore',
  'epssScore',
  'epssPercentile',
  'riskScore',
];
export const requiredDefaultFieldOptions = [
  FindingSeverity.CRITICAL,
  FindingSeverity.HIGH,
  FindingSeverity.MEDIUM,
];

export const slaRuleConditionOptions: Array<ExtendedFilterCategory> =
  notificationRuleConditionOptions;
