import { useEffect, useMemo, useState } from 'react';
import {
  useDeleteNotificationRuleMutation,
  useFetchNotificationRulesQuery,
  useUpdateNotificationRuleMutation,
} from 'Settings/store/api';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { useApplicationLogosFetchQuery } from '../../../Application/store/api';
import { SettingsLayout } from '../SettingsLayout/SettingsLayout';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import ForbiddenPage from '../../../Common/components/ForbiddenPage';
import SettingsGridColumns from '../SettingsLayout/settings-data-grid';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import { NotificationRuleDrawerPanel } from './NotificationRuleDrawerPanel/NotificationRuleDrawerPanel';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SettingsTabPanel } from 'Settings/interfaces/SettingTabPanel.enum';
import { DrawerMode } from 'shared/enums/business-unit.enum';
import { NotificationRule } from 'shared/models/data/notification-rule.model';

export function NotificationRuleList() {
  const { t: translation } = useTranslation();
  const [shouldIgnoreRowClick, setShouldIgnoreRowClick] =
    useState<boolean>(false);
  const [tableItems, setTableItems] = useState<NotificationRule[] | null>(null);
  const userPermissions = usePermissions();
  const hasNotificationRuleReadPermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_NOTIFICATION_RULES_READ
  );

  const [searchParams, setSearchParams] = useQueryParams();

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const { data: notificationRules, isLoading: notificationRulesLoading } =
    useFetchNotificationRulesQuery();

  const [updateNotificationRule, { isLoading: notificationRuleUpdating }] =
    useUpdateNotificationRuleMutation();

  useEffect(() => {
    if (searchParams.mode) {
      setPopoverOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (notificationRules) {
      setTableItems(notificationRules);
    }
  }, [notificationRules]);

  const notificationRulesColumns = useMemo(() => {
    return SettingsGridColumns.getNotificationRulesColumns({
      t: translation,
      useDeleteMutation: useDeleteNotificationRuleMutation,
      onDelete,
      onUpdate: updateNotificationRule,
      setShouldIgnoreRowClick,
      requiredPermissions: [
        ApplicationPermission.SETTINGS_NOTIFICATION_RULES_WRITE,
      ],
      isUpdating: notificationRuleUpdating,
    });
  }, []);

  const drawerOpenHandler = () => {
    setSearchParams({
      activeTabId: SettingsTabPanel.AUTOMATION_RULES,
      mode: DrawerMode.CREATE,
    });
  };

  const rowClickHandler = (rule: NotificationRule) => {
    if (
      userPermissions.includes(
        ApplicationPermission.SETTINGS_NOTIFICATION_RULES_WRITE
      )
    ) {
      setSearchParams({
        ...searchParams,
        mode: DrawerMode.EDIT,
        ruleId: rule.id as string,
      });
    }
  };

  const drawerCloseHandler = () => {
    setPopoverOpen(false);
    setSearchParams({
      activeTabId: SettingsTabPanel.AUTOMATION_RULES,
    });
  };

  function onDelete(decision: boolean) {}

  if (!hasNotificationRuleReadPermission) return <ForbiddenPage />;

  return (
    <>
      <SettingsLayout<NotificationRule>
        headerProps={{
          setTableItems: setTableItems,
          items: notificationRules || [],
          filterPropertyName: 'name',
          onButtonClick: drawerOpenHandler,
          buttonLabel: (
            <FormattedMessage
              id="settings.details.addNewAutomationRule"
              defaultMessage="Add New Automation Rule"
            />
          ),
          buttonType: 'button',
          buttonRequiredPermissions: [
            ApplicationPermission.SETTINGS_NOTIFICATION_RULES_WRITE,
          ],
        }}
        tableProps={{
          rowData: tableItems || notificationRules,
          columnDefs: notificationRulesColumns,
          isLoading: notificationRulesLoading,
          onItemClick: (item) => rowClickHandler(item),
          deleteMutation: useDeleteNotificationRuleMutation,
          ignoreRowClick: shouldIgnoreRowClick,
        }}
        fetchData={() => {}}
      />
      <PopoverWrapper
        showPopover={popoverOpen}
        handleClose={drawerCloseHandler}
      >
        <NotificationRuleDrawerPanel onCancel={drawerCloseHandler} />
      </PopoverWrapper>
    </>
  );
}
