import {
  findingSeverityListOptions,
  SeverityValueMapper,
} from 'FindingDetails/interfaces/severity';
import {
  RuleProperty,
  RuleStatus,
} from 'Settings/interfaces/RiskCustomizationConfig';
import { ConfigurationFieldValue } from 'shared/components/MultiSelectConfigurationField/MultiSelectConfigurationField';
import {
  SlaRuleAdditionalFormData,
  SlaRuleBaseFormData,
  SlaRuleFormData,
} from 'shared/fixtures/data/sla-rules.data';
import {
  CategoryState,
  ExtendedFilterCategory,
} from 'shared/models/data/data-filter.model';
import {
  SlaConfigRuleModel,
  SlaConfigRuleValues,
} from 'shared/models/data/sla-config-rule.model';
import { SettingsDataHandler } from './settings-data.handler';

export class SlaRuleDataHandler extends SettingsDataHandler {
  transformSlaRuleDataToFormData(slaRule: SlaConfigRuleModel): SlaRuleFormData {
    let ruleValues: Array<ConfigurationFieldValue> = [];

    let ruleProperties: Record<string, CategoryState> =
      this.transformRulePropertiesToFormProperties(slaRule.properties);

    const sortedSeverityEntries = Object.entries(slaRule.values)
      .map(([severityKey, severityValue]) => {
        const severityOption = findingSeverityListOptions.find(
          (option) => option.value === severityKey
        );
        return {
          severityOption,
          severityValue: severityValue ?? 0,
        };
      })
      .filter(({ severityOption }) => severityOption !== undefined)
      .sort((a, b) => {
        const aSeverityValue =
          SeverityValueMapper[a.severityOption?.label ?? ''] || 0;
        const bSeverityValue =
          SeverityValueMapper[b.severityOption?.label ?? ''] || 0;
        return bSeverityValue - aSeverityValue;
      });

    for (const { severityOption, severityValue } of sortedSeverityEntries) {
      if (severityOption && severityValue !== 0) {
        ruleValues.push({
          values: [
            severityOption,
            { value: String(Math.floor(severityValue / 3600)) },
          ],
          disableDelete: Boolean(slaRule.isDefault),
        });
      }
    }

    return {
      name: slaRule.name,
      description: slaRule.description || '',
      properties: ruleProperties,
      values: ruleValues,
    };
  }

  transformSlaRuleFormDataToPostData(
    baseFormData: SlaRuleBaseFormData,
    additionalFormData: SlaRuleAdditionalFormData,
    categories?: Array<ExtendedFilterCategory>
  ): SlaConfigRuleModel {
    let values: Partial<SlaConfigRuleValues> = {};

    let properties: Array<RuleProperty> =
      this.transformFormPropertiesToRuleProperties(
        additionalFormData.properties,
        categories
      );

    for (const valueItem of additionalFormData.values) {
      const valueItemKey = valueItem.values[0].value;
      const valueItemValue = valueItem.values[1].value;

      values[valueItemKey as unknown as keyof SlaConfigRuleValues] =
        +valueItemValue * 3600;
    }

    return {
      name: baseFormData.name,
      description: baseFormData.description,
      properties: properties,
      values: values,
      status: RuleStatus.ENABLED,
    };
  }
  transformRuleResponse(data: Array<any>) {
    return data.sort((a, b) => {
      if (a.isDefault && !b.isDefault) return -1;
      if (!a.isDefault && b.isDefault) return 1;
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }
}
